import { logger } from '@/Services/Logger';
import { notifyError } from '@/Services/Notification';
import { useState, useEffect, useCallback, useRef, useMemo } from 'react';

export type QueryStatus = 'idle' | 'error' | 'success' | 'loading';

export interface useRequestOptions<T, Data> {
  notifyErrorEnabled?: boolean;
  enabled?: boolean;
  dependencies?: any[];
  onSuccess?: (data: T) => void;
  selectData?: (data: T) => Data;
}

type queryAbortParam = { signal: AbortSignal };

export default function useRequest<T, Data = T>(queryFn: (arg?: queryAbortParam) => Promise<T>, _options?: useRequestOptions<T, Data>) {
  const [status, setStatus] = useState<QueryStatus>('idle');
  const [data, setData] = useState<Data | null>(null);
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState(_options?.enabled ?? true);

  const abortControllerRef = useRef<AbortController | null>(null);

  const options: Required<useRequestOptions<T, Data>> = useMemo(() => {
    return {
      enabled: _options?.enabled ?? true,
      notifyErrorEnabled: _options?.notifyErrorEnabled ?? true,
      dependencies: _options?.dependencies || [],
      onSuccess: _options?.onSuccess ?? (() => {}),
      // @ts-expect-error
      selectData: _options?.selectData ?? (data => data as Data),
    };
  }, [_options]);

  const queryFnRef = useRef({
    query: queryFn,
    onSuccess: options.onSuccess,
    selectData: options.selectData,
  });

  queryFnRef.current.query = queryFn;
  queryFnRef.current.onSuccess = options.onSuccess;
  queryFnRef.current.selectData = options.selectData;

  const handleError = (error: unknown) => {
    setError(error);
    setStatus('error');
    setLoading(false);
    if (options.notifyErrorEnabled) {
      notifyError(error);
      logger.log('error', error);
    }
  };

  const runQuery = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef?.current?.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const handleSuccess = (res: T) => {
      queryFnRef.current.onSuccess(res);
      setData(queryFnRef.current.selectData(res));
      setStatus('success');
      setLoading(false);
    };

    setStatus('loading');
    setLoading(true);

    queryFnRef.current
      .query({ signal: abortController.signal })
      .then(handleSuccess)
      .catch(error => {
        if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
          handleError(error);
        }
      });
  }, []);

  useEffect(() => {
    if (options.enabled) {
      runQuery();
    }

    // return () => {
    //   if (abortControllerRef.current) {
    //     abortControllerRef.current.abort();
    //   }
    // };
  }, [...options.dependencies]);

  return { data, loading, error, status, refetch: runQuery };
}
