import React, { FC } from 'react';
import { ButtonsStyle } from './newStyle';
import CommonSvg, { svgNames } from '@/Components/atoms/CommonSvg';
import { LIST_GRID_TYPE } from '@/Types/CommonTypes';
import { BiGridAlt } from 'react-icons/bi';
import { colors } from '@/styles/colors';
import { useGlobalSiteSettingsContext } from '@/Context/GlobalSiteSettings/useGlobalSiteSettingsContext';

type IGridTypeButtonsProps = {
  additionalButtons?: React.ReactNode;
};

const GridTypeButtons: FC<IGridTypeButtonsProps> = ({ additionalButtons }) => {
  const listType = useGlobalSiteSettingsContext(v => v.state.userPreferences.gridType);
  const changeUserPreference = useGlobalSiteSettingsContext(v => v.actions.changeUserPreference);

  const masonryBtn = (
    <button key={1} onClick={() => changeUserPreference('gridType', LIST_GRID_TYPE.MASONRY)} className={listType === LIST_GRID_TYPE.MASONRY ? 'active' : ''}>
      <CommonSvg name={svgNames.Grid} />
    </button>
  );
  const gridBtn = (
    <button key={3} onClick={() => changeUserPreference('gridType', LIST_GRID_TYPE.GRID)} className={listType === LIST_GRID_TYPE.GRID ? 'active' : ''}>
      <BiGridAlt color={colors.primary} />
    </button>
  );
  const listBtn = (
    <button key={2} onClick={() => changeUserPreference('gridType', LIST_GRID_TYPE.HORIZONTAL)} className={listType === LIST_GRID_TYPE.HORIZONTAL ? 'active' : ''}>
      <CommonSvg name={svgNames.List} />
    </button>
  );

  let buttons: any = [];
  if (1) {
    buttons = [ gridBtn, listBtn];
  }
  // if (additionalButtons) {
  //   buttons.unshift(additionalButtons);
  // }

  return <ButtonsStyle>{buttons.length && <div className='btns'>{buttons}</div>}</ButtonsStyle>;
};

export default GridTypeButtons;
