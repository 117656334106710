import React, { FC } from 'react';
import Loader from '@/Components/atoms/Loader';
import { colors } from '@/styles/colors';
import { RequestStatus } from '@/Services/Axios';

import { TitledCardWrapper } from './style';

interface ITitledCardProps {
  children: React.ReactNode;
  title: React.ReactNode;
  isLoading?: boolean;
};

const BaseTitledCard: FC<ITitledCardProps> = ({ title, isLoading = false, children }) => {
  return (
    <TitledCardWrapper>
      <div className='card-header'>
        {title}
      </div>
      <div className='card-content'>
        {
          isLoading
          ? <Loader color={colors.primary} type='block' size={48} />
          : children
        }
      </div>
    </TitledCardWrapper>
  )
}

export default BaseTitledCard;
