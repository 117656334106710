import { QS } from '@/Services/QS';
import * as qs from 'qs';
import { IFilterParamFieldValue } from '../Category';
import { CURRENCY, PRODUCT_STATE, SORT_KEYS, SORT_TYPES } from './IProduct';
import { LocationType, PageAbleParams } from '@/Types/CommonTypes';
import { SearchProductBody } from '@/Hooks/useSearchProducts';
import { categoryService } from '@/Models/Category/category.service';
import { addressService } from '@/Models/Region/region.service';
import { logger } from '@/Services/Logger';

export type GET_PRODUCT_PARAMS = PageAbleParams &
  LocationType & {
    name?: string;
    created_by?: string;

    region_id?: number;
    district_id?: number;

    premiumCount?: number;
    orderNum?: number;

    max_price?: number;
    min_price?: number;
    sort?: SORT_KEYS;
    sort_type?: SORT_TYPES;
    condition?: PRODUCT_STATE;
    currency_type?: CURRENCY;

    category_id?: number;
    params?: IFilterParamFieldValue[];
  };

class ProductModel {
  // NOTE: API uchun request yuborishdagi paramsni tayyorlash
  public prepareSearchParams(searchBody: SearchProductBody & LocationType & PageAbleParams): GET_PRODUCT_PARAMS {
    const sort = searchBody.sort ?? SORT_KEYS.NEWEST;

    return {
      page: searchBody.page - 1,
      size: searchBody.size,
      user_lang: searchBody.user_lang,
      user_lat: searchBody.user_lat,

      // OPTIONAL
      category_id: searchBody.category_id,
      params: searchBody.params && searchBody.params.length ? this.prepareParamValuesToReq(searchBody.params) : undefined,

      // headerdagi input, orderNum mount bo'lganda random yaratiladi, premiumCount window sizega qarab chiqishi kerak (mobileda har doim 2ta)
      name: searchBody.name,
      orderNum: searchBody.orderNum,
      premiumCount: searchBody.premiumCount,

      // user pagega kirganda keladi
      created_by: searchBody.created_by,

      // address headerdagi selectdan keladi
      ...this.getRegionOrDistrictId(searchBody.addressSlug),

      // asosan valuelar filter carddan keladi
      condition: searchBody.condition,
      currency_type: searchBody.currency_type,
      max_price: searchBody.max_price,
      min_price: searchBody.min_price,
      sort: sort,
      sort_type: sort!.split(',')[1] as SORT_TYPES,
      // sort: searchBody.sort,
    };
  }

  // NOTE: query paramsni URL dan parse qilib INITIAL_VALUES olish
  public getQueryParamsFromUrl(path: string): Omit<SearchProductBody, 'sort' | 'sort_type'> & {
    page: number;
    sort: SORT_KEYS;
    sort_type: SORT_TYPES;
  } {
    const params = this.getParamsFromString(path);

    return {
      condition: this.isExist(params.condition) ? (params.condition as PRODUCT_STATE) : undefined,
      currency_type: this.isExist(params.currency_type) ? (params.currency_type as CURRENCY) : undefined,
      max_price: this.isExist(params.max_price) ? +(params.max_price as string) : undefined,
      min_price: this.isExist(params.min_price) ? +(params.min_price as string) : undefined,
      name: this.isExist(params.name) ? (params.name as string) : undefined,
      page: this.isExist(params.page) ? +(params.page as string) : 1,
      sort: this.isExist(params.sort) ? (params.sort as SORT_KEYS) : SORT_KEYS.NEWEST,
      sort_type: this.isExist(params.sort_type) ? (params.sort_type as SORT_TYPES) : SORT_TYPES.DESC,

      params:
        params.params && params.params.length && params.params instanceof Array
          ? params.params.map((param: any) => {
              return {
                id: param.id,
                max: param.max ?? undefined,
                min: param.min ?? undefined,
                paramValues: param.paramValues ?? undefined,
              } as IFilterParamFieldValue;
            })
          : undefined,
    };
  }

  // NOTE: query paramsni URL ga push qilish uchun datani tayyorlash
  public prepareQueryParamsToUrl(searchBody: SearchProductBody & Partial<PageAbleParams>): string {
    const queryParams = {
      condition: this.isExist(searchBody.condition) ? (searchBody.condition as PRODUCT_STATE) : undefined,
      currency_type: this.isExist(searchBody.currency_type) ? (searchBody.currency_type as CURRENCY) : undefined,
      max_price: this.isExist(searchBody.max_price) ? searchBody.max_price : undefined,
      min_price: this.isExist(searchBody.min_price) ? searchBody.min_price : undefined,
      name: this.isExist(searchBody.name) ? searchBody.name : undefined,
      page: this.isExist(searchBody.page) && searchBody.page !== 1 ? searchBody.page : undefined,
      sort: this.isExist(searchBody.sort) && searchBody.sort !== SORT_KEYS.NEWEST ? searchBody.sort : undefined,
      params: searchBody.params && searchBody.params.length ? this.prepareParamValues(searchBody.params) : undefined,
    };

    const existingValues = Object.fromEntries(Object.entries(queryParams).filter(([key, val]) => !!val));
    if (Object.keys(existingValues).length) {
      return `?${QS.stringify(existingValues)}`;
    } else {
      return '';
    }
  }

  public isThereAnyParamValue(params: IFilterParamFieldValue[]): boolean {
    return !!params.find(param => param.paramValues?.length || param.min || param.max);
  }

  private prepareParamValues(params: IFilterParamFieldValue[]) {
    return params
      .filter(param => param.paramValues?.length || param.min || param.max)
      .map(param => {
        return {
          id: param.id,
          ...(param.max ? { max: param.max } : {}),
          ...(param.min ? { min: param.min } : {}),
          ...(param.paramValues?.length ? { paramValues: param.paramValues } : {}),
        };
      });
  }

  private prepareParamValuesToReq(params: IFilterParamFieldValue[]) {
    return params
      .filter(param => param.paramValues?.length || param.min || param.max)
      .map(param => {
        return {
          id: param.id,
          ...(param.max ? { max: param.max } : {}),
          ...(param.min ? { min: param.min } : {}),
          // @ts-expect-error: TODO paramValues da objectni o'rniga faqat number saqlash kerak
          ...(param.paramValues?.length ? { paramValues: param.paramValues.map(p => p.id) } : {}),
        };
      });
  }

  private getParamsFromString(url: string) {
    const splitUrl = url.split('?');
    if (splitUrl.length > 1) {
      const queryString = splitUrl[1];
      try {
        const parsedParams = qs.parse(queryString, {
          allowDots: true,
        });

        return parsedParams;
      } catch (error) {
        logger.error(`Error parsing query string: ${error}`);
        return {};
      }
    } else {
      return {};
    }
  }

  private isExist(value: unknown): boolean {
    if (value != undefined && value !== '') {
      return true;
    } else {
      return false;
    }
  }

  private getRegionOrDistrictId(slug?: string): { region_id?: number; district_id?: number } {
    if (slug) {
      const value = addressService.getAddressValue(slug);
      if (value && value.type === 'region') {
        return {
          region_id: value.id,
        };
      } else if (value) {
        return {
          district_id: value.id,
        };
      } else {
        return {};
      }
    } else {
      return {};
    }
  }

  /**
   * @deprecated Use `prepareSearchParams` instead.
   */
  generateGetProductParams(): GET_PRODUCT_PARAMS {
    return {
      page: 0,
      size: 50,
      user_lat: 1,
      user_lang: 2,
    };
  }

  /**
   * @deprecated Use `getQueryParamsFromUrl` instead.
   */
  getParamsFromQuery(query: string): Omit<GET_PRODUCT_PARAMS, 'size' | 'user_lat' | 'user_lang'> {
    function getExistingProp(obj: object, key: keyof GET_PRODUCT_PARAMS) {
      if (obj.hasOwnProperty(key)) {
        try {
          switch (key) {
            case 'page': {
              // @ts-ignore
              return +obj[key] ?? 1;
            }
            case 'name': {
              // @ts-ignore
              return obj[key];
            }
            case 'max_price':
            case 'min_price':
            case 'sort':
            case 'sort_type':
            case 'condition':
            case 'currency_type': {
              // @ts-ignore
              return obj[key];
            }
          }
        } catch (error) {
          logger.log(error);
          return null;
        }
      } else {
        return null;
      }
    }

    try {
      const params = QS.parse(query, { allowDots: true });

      // NOTE: shu paramslar olinadi
      const takenParams = {
        page: getExistingProp(params, 'page'),
        name: getExistingProp(params, 'name'),

        // BASIC FILTER
        max_price: getExistingProp(params, 'max_price'),
        min_price: getExistingProp(params, 'min_price'),
        sort: getExistingProp(params, 'sort'),
        sort_type: getExistingProp(params, 'sort_type'),
        condition: getExistingProp(params, 'condition'),
        currency_type: getExistingProp(params, 'currency_type'),
      };

      const trueValues = Object.entries(takenParams).filter(([key, value]) => value != null);

      // @ts-ignore
      return trueValues;
    } catch (error) {
      logger.log(error);
      return {
        page: 1,
      };
    }
  }
}

export const productModel = new ProductModel();
