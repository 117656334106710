import React, { FC, memo, useCallback } from 'react';
import BaseTitledCard from '@/Components/atoms/BaseComponents/BaseTitledCard';
import { IMinCategory } from '@/Models/Category';
import { useTranslation } from 'next-i18next';
import CategoryListMobile from '../CategoryListMobile';


interface ICategoryListProps {
  onSelect: (category: IMinCategory) => void;
  closeOnAction?: Function;
  withoutTitle?: boolean;
};

const CategoryList: FC<ICategoryListProps> = ({ onSelect = () => {}, closeOnAction, withoutTitle }) => {
  const { t } = useTranslation();

  const onSelectCategory = useCallback((category: IMinCategory) => {
    onSelect(category);
    closeOnAction?.();
  }, [onSelect]);

  if(withoutTitle) {
    return <CategoryListMobile onSelect={onSelectCategory} closeOnAction={() => {}} selectOnlyChild={false} />;
  }

  return (
    <BaseTitledCard title={t('categories')}>
      <CategoryListMobile onSelect={onSelectCategory} closeOnAction={() => {}} selectOnlyChild={false} />
    </BaseTitledCard>
  )
};

export default memo(CategoryList);
