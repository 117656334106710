export type deviceType = 'mobile' | 'tablet' | 'miniComputer' | 'computer';

// HOZIR ISHLATILMAYAPTI
export function getPremiumCount(width: number): number {
  switch (true) {
    case width >= 1240:{
      return 5;
    }
    case width >= 1100:{
      return 4;
    }
    case width >= 991.98:{
      return 3;
    }
    case width >= 767.98:{
      return 2;
    }
    // TODO isMobilega qarab ishlashi kerak
    default:{
      return 2;
    }
  }
}

export function getPremiumCountByDevice(device: deviceType): number {
  switch (device) {
    case 'computer':{
      return 5;
    }
    case 'miniComputer':{
      return 4;
    }
    case 'tablet':{
      return 3;
    }
    case 'mobile':{
      return 2;
    }
    default:{
      return 2;
    }
  }
}
