import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { StyledCategoryList, StyledDiv } from './style';
import Loader from '@/Components/atoms/Loader';
import { colors } from '@/styles/colors';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import BaseButton from '@/Components/atoms/BaseComponents/BaseButton';
import { IMinCategory } from '@/Models/Category';
import { useRouter } from 'next/router';
import { getNormalizeCategoryParam } from '@/Utils/getNormalizedQueryParams';
import { useTranslation } from 'next-i18next';
import { getNameKeyByLang } from '@/Utils/getNameKeyByLang';
import { findCategoryById } from '@/Utils/findCategoryById';
import { useFetch } from '@/Hooks/useFetch';
import CategoryApi from '@/Services/Axios/Controllers/CategoryApi';
import { RequestStatus } from '@/Services/Axios';

interface ICategoryListProps {
  onSelect: (category: IMinCategory) => void;
  closeOnAction?: Function;
  selectOnlyChild?: boolean;
}

const CategoryListMobile: FC<ICategoryListProps> = ({ onSelect = () => {}, closeOnAction, selectOnlyChild = true }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as 'uz' | 'ru';
  const router = useRouter();
  const [openedCategory, setOpenedCategory] = useState<IMinCategory | null>(null);
  const [activeCategory, setActiveCategory] = useState<IMinCategory | null>(getNormalizeCategoryParam(router.query));
  const [categoryState, fetchCategoryState] = useFetch<IMinCategory[]>();
  const isLoading = categoryState.status === RequestStatus.LOADING || categoryState.status === RequestStatus.IDLE;

  const handleOpenCategory = useCallback((cat: IMinCategory) => {
    setOpenedCategory(cat);
  }, []);

  const handleBack = useCallback(() => {
    if (!openedCategory?.parentId) {
      setOpenedCategory(null);
    } else if (categoryState.data) {
      setOpenedCategory(findCategoryById(openedCategory.parentId, categoryState.data)!);
    }
  }, [openedCategory, categoryState.data]);

  const setOpenedCat = useCallback((cat: IMinCategory) => {
    setOpenedCategory(cat);
  }, []);

  const onSelectCategory = useCallback(
    (category: IMinCategory) => {
      onSelect(category);
      setActiveCategory(category);
      closeOnAction?.();
    },
    [onSelect]
  );

  useEffect(() => {
    fetchCategoryState(CategoryApi.getAllCategories);
  }, []);

  return (
    <StyledDiv>
      {isLoading ? (
        <Loader color={colors.primary} type='block' size={48} />
      ) : openedCategory != null ? (
        <>
          <BaseButton onClick={handleBack} className='back-btn mb-2'>
            <span>{t('go_back')}</span> <FaAngleLeft />
          </BaseButton>
          <ChildCategories
            setOpenedCat={setOpenedCat}
            parent={openedCategory}
            onSelect={onSelectCategory}
            activeCategory={activeCategory}
            selectOnlyChild={selectOnlyChild}
          />
        </>
      ) : (
        <StyledCategoryList>
          {categoryState.data?.map(cat => {
            const hasChildren = !!(cat.childs.length > 0);

            return (
              <li key={cat.id} onClick={() => handleOpenCategory(cat)}>
                <span>{cat[getNameKeyByLang(currentLanguage)]}</span>
                {hasChildren && <FaAngleRight />}
              </li>
            );
          })}
        </StyledCategoryList>
      )}
    </StyledDiv>
  );
};

interface ChildCategoriesProps {
  parent: IMinCategory;
  onSelect: (category: IMinCategory) => void;
  setOpenedCat: (category: IMinCategory) => void;
  activeCategory: IMinCategory | null;
  selectOnlyChild: boolean;
}

const ChildCategories: FC<ChildCategoriesProps> = ({ parent, onSelect, activeCategory, selectOnlyChild, setOpenedCat }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as 'uz' | 'ru';

  const onClickItem = (cat: IMinCategory, hasChildren: boolean) => {
    if (hasChildren) {
      setOpenedCat(cat);
    } else {
      onSelect(cat);
    }
  };

  return (
    <>
      <StyledCategoryList>
        {!selectOnlyChild && (
          <li onClick={() => onSelect(parent)} className={activeCategory?.id == parent.id ? 'active' : ''}>
            <span>{t('allChildCategories', { key: parent[getNameKeyByLang(currentLanguage)] })}</span>
          </li>
        )}
        {parent.childs?.map(cat => {
          const hasChildren = !!(cat.childs.length > 0);

          return (
            <li key={cat.id} onClick={() => onClickItem(cat, hasChildren)} className={activeCategory?.id == cat.id ? 'active' : ''}>
              <span>{cat[getNameKeyByLang(currentLanguage)]}</span>
              {hasChildren && <FaAngleRight />}
            </li>
          );
        })}
      </StyledCategoryList>
    </>
  );
};

export default memo(CategoryListMobile);
