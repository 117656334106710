import * as React from 'react';
import { default as MuiPagination, PaginationProps } from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import PaginationStartIcon from '../CommonSvg/PaginationStartIcon';
import PaginationEndIcon from '../CommonSvg/PaginationEndIcon';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

interface IPaginationRoundedProps extends PaginationProps {
  alignItems: 'center' | 'flex-start' | 'flex-end';
}

export function PaginationRounded({ count, page, shape = 'rounded', alignItems = 'center', ...rest }: IPaginationRoundedProps) {
  const { t } = useTranslation();
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) return null;

  return (
    <Stack spacing={2} alignItems={alignItems}>
      <MuiPagination
        count={count}
        shape={shape}
        page={page}
        {...rest}
        renderItem={item => {
          if (item.type === 'next' || item.type === 'previous') {
            return (
              <div
                className='MuiButtonArrow'
                onClick={item.onClick}
                style={{
                  border: '1px solid transparent',
                  backgroundColor: '#fff',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'auto',
                  height: '100%',
                  color: '#0C88C6',
                  cursor: 'pointer',
                  marginRight:item.type === 'next' ? '0' : '30px',
                  marginLeft:item.type === 'next' ? '30px' : '0',
                  borderRadius: item.type === 'next' ? '12px' : '12px',
                  padding:'0 20px'
                }}
              >
                {item.type === 'next' ? (
                  <button
                    style={{
                      fontFamily:'Inter',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: 'normal',
                      color: '#0C88C6',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 10,
                      background: 'transparent',
                      border: 'none',
                    }}
                    disabled={item.disabled}
                  >
                    <span>{t('next')}</span>
                    <FaAngleRight/>
                  </button>
                ) : (
                  <button
                    style={{
                      fontFamily:'Inter',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: 'normal',
                      color: '#0C88C6',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 10,
                      background: 'transparent',
                      border: 'none',
                    }}
                    disabled={item.disabled}
                  >
                     <FaAngleLeft/>
                    <span>{t('back')}</span>
                  </button>
                )}
              </div>
            );
          }

          return (
            <PaginationItem
              {...item}
              sx={{
                borderRadius: '12px',
                backgroundColor: '#fff !important',
                fontFamily:"Inter",
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: 'normal',
                width: '40px',
              }}
            />
          );
        }}
      />
    </Stack>
  );
}
