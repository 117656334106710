import { styled } from '@mui/material/styles';

const StyledDiv = styled('div')`

  .back-btn {
    background-color: ${props => props.theme.customTheme.bg.primary};
    border-radius: 6px;
    padding: 10px 10px;
    width: 100%;
    font-size: ${props => props.theme.customTheme.sizes.small};
    & > span {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
`

const StyledCategoryList = styled('ul')`
  margin: 0;
  padding: 0;
  list-style: none;


  li {
    font-weight: 400;
    font-size: ${props => props.theme.customTheme.sizes.normal};
    line-height: 19px;
    color: ${props => props.theme.customTheme.colors.categoryLi} !important;
    cursor: pointer;
    padding: 10px 10px;

    display: flex;
    justify-content: space-between;

    svg {
      color: ${props => props.theme.customTheme.colors.secondary};
    }

    &:not(:last-child) {
      /* margin-bottom: 8px; */
    }
    &:hover {
      svg {
        color: ${props => props.theme.customTheme.colors.categoryLi};
      }
    }


    &.active, &:hover {
      background-color: ${props => props.theme.customTheme.bg.categoryHead};
      color: #fff;
      border-radius: 10px;
    }
  }

  @media (max-width: 767.98px) {
    li {
      font-size: ${props => props.theme.customTheme.sizes.middle};
      padding: 0.9rem 0.5rem;
    }
    span {
      font-size: ${props => props.theme.customTheme.sizes.middle};
    }
  }
`;

export {
  StyledCategoryList,
  StyledDiv
};
