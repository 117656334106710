import { notification as AntdNotification } from 'antd-notifications-messages';



export enum Notification_Types {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
};

class Notification {
  public type: Notification_Types = Notification_Types.info;
  public title: string = '';
  public message: string = '';
  public duration: number = 5000;


  
  public setType(type: Notification_Types) {
    this.type = type;
  };
  
  public setTitle(title: string) {
    this.title = title;
  };
  
  public setMessage(message: string) {
    this.message = message;
  };


  public call(type: Notification_Types, message: string) {
    AntdNotification({
      type,
      message,
      title: this.title,
      duration: this.duration,
    });
  };


  // NOTE - Promise .then ga 2-parametr qilib bervorsa bo'ladi
  public notifySuccess(message: string | any) {
    typeof message !== 'string' && (message = String(message));
    this.call(Notification_Types.success, message);
  }
  public notifyError(message: string | any) {
    typeof message !== 'string' && (message = String(message));
    this.call(Notification_Types.error, message);
  }
  public notifyWarning(message: string | any) {
    typeof message !== 'string' && (message = String(message));
    this.call(Notification_Types.warning, message);
  }
  public notifyInfo(message: string | any) {
    typeof message !== 'string' && (message = String(message));
    this.call(Notification_Types.info, message);
  }
};


const notificationApi = new Notification();
const notification = notificationApi.call.bind(notificationApi);

const notifySuccess = notificationApi.notifySuccess.bind(notificationApi);
const notifyError = notificationApi.notifyError.bind(notificationApi);
const notifyWarning = notificationApi.notifyWarning.bind(notificationApi);
const notifyInfo = notificationApi.notifyInfo.bind(notificationApi);


export {
  notification,
  notificationApi,

  notifySuccess,
  notifyError,
  notifyWarning,
  notifyInfo,
};
